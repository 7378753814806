export const LOGIN_LABELS = {
  USERNAME: 'Employee ID/Email',
  PASSWORD: 'Password',
  ERROR_MSG: {
    USERNAME: 'Employee ID/Email is required',
    PASSWORD: 'Password is required',
  },
};

export const DEFAULT_STORE = {
  WARNING: 'Warning',
  CURRENT_WORKING_TXT: 'You are currently working at',
  DEFAULT_STORE_TXT: 'You have selected to work at',
  WISH_TO_CONTINUE_TXT: 'Do you wish to continue',
};
